import React from 'react'
import { Field } from 'react-final-form'
import type { TMultiSelectValueType } from '@frontend/pole-ui/lib/components/MultiSelect'
import { MultiSelect } from '@frontend/pole-ui/lib/components/MultiSelect'
import type { FieldValidator } from 'final-form'
import { useData } from '@/logic/data'
import type { IRegion } from '@/types'
import { getValidateFunction } from '@/utils/validators'
import { ELoanPurposeFieldName } from './constants'
import { noPurposeValidator } from './helpers'
import { messages } from './messages'
import type { IMultiRegionSelectProps } from './types'

export const MultiRegionSelect: React.FC<IMultiRegionSelectProps> = props => {
  const { className, errorClassName, isRequired = true } = props
  const { data: regions } = useData<IRegion[]>('regions')

  const filteredRegions = regions
    ? regions.map(region => ({
        label: String(region.name), // Преобразуем code в строку
        value: String(region.code), // Преобразуем code в строку
      }))
    : [] // Если regions undefined, используем пустой массив

  const validate = getValidateFunction(() => {
    const validators: FieldValidator<TMultiSelectValueType[]>[] = []

    if (isRequired) {
      validators.push(noPurposeValidator)
    }

    return validators
  })

  return (
    <Field name={ELoanPurposeFieldName.CHECK_PICKER} validate={validate}>
      {fieldProps => {
        const errorText = fieldProps.meta.error && fieldProps.meta.touched ? fieldProps.meta.error : null

        return (
          <MultiSelect
            {...fieldProps.input}
            className={className}
            errorClassName={errorClassName}
            value={fieldProps.input.value}
            error={errorText}
            options={filteredRegions}
            placeholder={messages.multiSelect.placeholder}
            onChange={fieldProps.input.onChange}
            isRequired={isRequired}
          />
        )
      }}
    </Field>
  )
}
