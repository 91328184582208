import type { TMultiSelectValueType } from '@frontend/pole-ui/lib/components/MultiSelect'
import type { FieldValidator } from 'final-form'
import { messages } from './messages'

export const noPurposeValidator: FieldValidator<TMultiSelectValueType[]> = (value?: TMultiSelectValueType[]): string | undefined => {
  if (!value || !value.length) {
    return messages.error.required
  }

  return undefined
}
