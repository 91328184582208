import type { TMessages } from '@/types/TMessages'

export const messages = {
  multiSelect: {
    placeholder: 'Регион работы',
  },
  error: {
    required: 'Выберете минимум один регион',
  },
} satisfies TMessages
